*{
  margin:0;
  padding:0;
}

:root{
  --textFamily:'Lato', sans-serif;
  --blackColor: #2d2d2d;
  --blueColor:#0b0bb4;
  --blue2Color:rgb(188, 226, 255);
  --yellowColor: #bcd631;
  --background: 
}
.line{
  width: 100px;
  border-top: 3px solid var(--blueColor);
  margin-bottom: 50px;
  z-index: 20;
  opacity:1;
}
.page-title{
  text-transform: uppercase;
  color: var(--blackColor);
  font-weight:900;
  font-family: var(--textFamily);
  font-size:30px;
  padding-top:30px;
}