.custom-shape-divider-bottom-1742913998 {
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(181deg);
}

.custom-shape-divider-bottom-1742913998 svg {
    position: relative;
    display: block;
    width: calc(200% + 1.3px);
    height: 150px;
}

.custom-shape-divider-bottom-1742913998 .shape-fill {
    fill: var(--blue2Color);
}

.landingPage_content{
    background:  linear-gradient(
        to left top, 
        rgba(11, 11, 180, 0.3), 
        rgba(0, 0, 188, 0.8)
    ),url('https://res.cloudinary.com/hayteetech/image/upload/v1743088603/Mynewdawnschool/grad2_dfihrp.jpg');
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 90vh;
    position: relative;
    overflow: hidden;
    padding-top: 20px;
}
.landingPage_content .header{
    width: 100%;
    height: 100px;
}
.landingPage_content .header img{
    width: 110px;
}
.landingPage_content .header .headerContact{
    position: absolute;
    right: 0px;
    top: 40px;
    background: #fff;
    font-size: 14px;
    font-family: var(--textFamily);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    padding: 5px 25px;
    font-family: var(--textFamily);
}
.landingPage_content .header .headerContact .text{
    font-size: 15px;
    font-weight: 600;
    color: var(--blue2Color);
}
.landingPage_content .header .headerContact .phone{
    font-size: 15px;
    font-weight: 600;
    color: #474444;
}
.landingPage_content .bannerText{
    color: #fff;
    max-width: 500px;
    margin-top: 5%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.landingPage_content .bannerText .title{
    font-size: 40px;
    font-weight: 700;
    padding-bottom: 20px;
    /* line-height: 42px; */
}
.landingPage_content .bannerText .subtitle{
    font-size: 14px;
    font-family: var(--textFamily);
    padding-bottom: 40px;
    line-height: 25px;
}
.landingPage_content .bannerBtn{
    display: flex;
    align-items: center;
    gap: 20px
}
@media screen and (max-width: 768px) {
    .landingPage_content{
        height: fit-content;
        padding-bottom: 50px;
    }
    .landingPage_content .bannerText{
        text-align: center;
        margin-top: 20%;
        max-width: 100%;
        gap: 40px
    }
    .landingPage_content .bannerBtn{
        justify-content: center;
        margin-top: 40px;
    }

}
@media screen and (max-width: 540px) {
    .landingPage_content .header{
        width: 100%;
        height: 70px;
    }
    .landingPage_content .header img{
        width: 80px;
    }
    .landingPage_content .header .headerContact{
        display: none
    }
    .landingPage_content .bannerText{
        text-align: center;
        margin-top: 17%;
    }
    .landingPage_content .bannerBtn{
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
    }
    .landingPage_content .bannerText .title{
        font-size: xx-large;
    }
}
@media screen and (max-width: 409px) {
    .landingPage_content .bannerText .title{
        font-size: x-large;
        line-height: 29px;
    }
    .landingPage_content .bannerText .subtitle{
        font-size: 12px
    }
}
.landingPage_content .buttonNav{
    display: block;
    border-style: solid;
    border-radius: 50px;
    z-index: 155;
    width: auto;
    padding: 0 25px;
    height: 48px;
    background-color: var(--blue2Color);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    text-shadow: none;
    color: var(--blueColor);
    border-width: 0px;
    border-color: #fff;
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    font-family: var(--textFamily);
    font-style: normal;
    text-align: center;
    background-repeat: no-repeat;
    cursor: pointer;
    text-decoration: none;
    transition: 0.5s all ease;
}
.landingPage_content .buttonNav2{
    background: var(--blueColor);
    color: #fff
}
.landingPage_content .buttonNav:hover{
    -webkit-transform: translateY(-10px);
    transform: translateY(-4px);
    box-shadow: 0 5px 29px rgba(90, 175, 240, 0.901);
    border-width: 3px;
}


/* landing about css */
.landingPage_about{
    position: relative;
    padding: 50px 0px;
}
.landingPage_about .title{
    text-align: center;
    font-weight: 600;
    font-size: 40px;
    color: var(--blackColor);
    font-family: var(--textFamily);
}
.landingPage_about .subtitle{
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    color: var(--blueColor);
    font-family: var(--textFamily);
}
@media screen and (max-width: 604px) {
    .landingPage_about .title{
        font-size: xx-large;
    }
    .landingPage_about .subtitle{
        font-size: x-large
    }
}
@media screen and (max-width: 400px) {
    .landingPage_about .title{
        font-size: x-large;
        line-height: 29px;
    }
    .landingPage_about .subtitle{
        font-size: 12px
    }
}
.landingPage_about .about_content{
    display: flex;
    gap: 20px;
    align-items: flex-start;
    padding: 50px 0px;
}
@media screen and (max-width: 768px) {
    .landingPage_about .about_content{
        flex-direction: column;
    }
}
.landingPage_about .image{
    flex: 1;
    padding-bottom: 40px;
    position: relative;
}
.landingPage_about img{
    width: 100%;
    height:auto;
    border-radius: 30%;
}
.landingPage_about .img1{
    box-shadow: 0 5px 29px rgba(90, 175, 240, 0.901);
    border-width: 3px;
    width: 90%
}
.landingPage_about .img2{
    position: absolute;
    border-radius: 50%;
    right: 5px;
    bottom: -10px;
    width: 60%;
    
}
.landingPage_about .about_textContainer{
    flex: 1;
}

.landingPage_about .about_text{
    font-size: 14px;
    padding-bottom: 20px;
    text-align: justify;
    color: var(--blackColor);
    font-family: var(--textFamily);
}

.landingPage_about .WhatWeDo{
    display: block;
    background: rgba(255, 255, 255, 0);
    background-image: url('https://res.cloudinary.com/hayteetech/image/upload/v1743088598/Mynewdawnschool/background_v40vdb.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-style: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2px;
    border-radius: 0px;
    width: 100%;
    height: auto;
    position: relative;
    padding: 50px 0px;
}
.landingPage_about .WhatWeDo .contentDiv{
    background: rgba(240, 247, 252, 1);
    padding: 50px;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 30px;
}
@media screen and (max-width: 768px) {
    .landingPage_about .WhatWeDo .contentDiv{
        flex-direction: column;
    }
    
}
.landingPage_about .WhatWeDo .buttonNav{
    text-decoration: none;
    transition: 0.5s all ease;
    flex: 1;
}
.landingPage_about .WhatWeDo .buttonNav:hover{
    -webkit-transform: translateY(-10px);
    transform: translateY(-4px);
    box-shadow: 0 5px 29px rgba(90, 175, 240, 0.901);
}
.landingPage_about .WhatWeDo .card{
    padding: 10px;
    border: none;
    background: #fff;
}
.landingPage_about .WhatWeDo .card .cardImage{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
}
.landingPage_about .WhatWeDo .card img{
    width: 30%;
    height: auto;
    
}
.landingPage_about .WhatWeDo .card .cardTitle{
    font-size: 20px;
    font-weight: 600;
    padding: 10px 0px;
    color: var(--blackColor);
    font-family: var(--textFamily);
    text-align: center;
}
.landingPage_about .WhatWeDo .card .cardText{
    font-size: 14px;
    padding: 10px 0px;
    color: var(--blackColor);
    font-family: var(--textFamily);
    text-align: center;
    min-height: 120px;
}